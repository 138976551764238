import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { globalHistory } from "@reach/router";

export const NavigationLinks = ({ currentPath }) => {
  const pathname = globalHistory.location.pathname;
  const data = useStaticQuery(graphql`
    query {
      allStrapiNavigation {
        edges {
          node {
            data {
              attributes {
                link
                name
                weight
              }
            }
          }
        }
      }
    }
  `);

  const navItems = data?.allStrapiNavigation?.edges[0]?.node?.data
    ?.map((x) => x.attributes)
    .sort((a, b) => a.weight - b.weight);

  if (!navItems) return null;

  const isActive = (link) => {
    if (link == "/") return pathname === "/";
    return pathname.includes(link);
  };

  return (
    <ul className="flex flex-col justify-center items-center mt-9 space-y-7 lg:flex-row lg:space-y-0 lg:space-x-7 lg:mt-0">
      {navItems.map((navItem, index) => (
        <li key={index}>
          <Link
            to={navItem.link}
            className={`nav-link lowercase ${
              isActive(navItem.link) ? "is-active" : ""
            }`}
          >
            {navItem.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};
