import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { NavigationLinks } from "./NavigationLinks";
import Logo from "../../images/logo-2d.svg";
import { BurgerMenuIcon } from "../../images/BurgerMenuIcon";
import { CloseIcon } from "../../images/CloseIcon";
import { CtaButtonText } from "../../reusables/Mixed";
import { isNotGetStatedPage } from "../../reusables/utils";
import { globalHistory } from "@reach/router";

export const Navbar = (props) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState(false);
  const pathname = globalHistory.location.pathname;

  return (
    <nav>
      <div className="max-w-full mx-auto px-4 sm:px-8 lg:px-10">
        <div className="relative flex items-center justify-between h-20">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-lg hover:text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuIsOpen ? (
                <CloseIcon classes="block h-6 w-6" />
              ) : (
                <BurgerMenuIcon classes="block h-6 w-6" />
              )}
            </button>
          </div>
          <div className="flex-1 flex items-center justify-end sm:justify-center lg:items-stretch lg:justify-between">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center">
                <img
                  className="block h-10 w-auto pr-2 sm:pr-0"
                  src={Logo}
                  alt="Workflow"
                />
                <span className="hidden sm:block text-lg lg:text-2xl font-medium ml-5  hover:text-primary transition-all duration-300">
                  nova web masters
                </span>
              </Link>
            </div>
            <div className="hidden items-center justify-center lg:flex lg:ml-6 ">
              <NavigationLinks />
              {isNotGetStatedPage(pathname) && (
                <Link to="/get-started" className="btn btn-sm ml-10">
                  <CtaButtonText />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {mobileMenuIsOpen ? (
        <div className="lg:hidden" id="mobile-menu">
          <NavigationLinks />
        </div>
      ) : null}
    </nav>
  );
};
