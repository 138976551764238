import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

export const Title = ({ children, className }) => {
  return (
    <span
      className={`block text-xl font-medium lowercase text-center mb-7 lg:text-2xl lg:text-left lg:mb-8 xl:mb-10 xl:text-3xl ${className}`}
    >
      {children}
    </span>
  );
};

export const ReadMore = ({ to = "/", className = "" }) => {
  return (
    <Link
      to={to}
      className={`text-primary font-medium transition-all duration-300 hover:text-black text-sm sm:text-base md:text-lg ${className}`}
    >
      Read More
    </Link>
  );
};

export const CtaButtonText = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiHomePage {
        nodes {
          data {
            attributes {
              CtaBtnText
            }
          }
        }
      }
    }
  `);

  const ctaBtnText =
    data?.allStrapiHomePage?.nodes[0]?.data?.attributes?.CtaBtnText;

  if (!ctaBtnText) return <span>Get Started</span>;
  return <span>{ctaBtnText}</span>;
};

export const SpinLoader = () => (
  <svg
    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
