import * as React from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import "./global.css";

export const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main> {children}</main>
      <Footer />
    </>
  );
};
