const isBrowser = typeof window !== "undefined";

export const isNotGetStatedPage = (pathname) => {
  return !pathname.includes("get-started");
};

export const scrollTo = (id) => {
  if (!isBrowser || !window) return;
  const element = window.document.getElementById(id);
  if (!element) return;
  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};
