import * as React from "react";
import { Link } from "gatsby";
import { NavigationLinks } from "./NavigationLinks";
import Logo from "../../images/logo-2d.svg";

export const Footer = () => {
  return (
    <footer className="pt-6 pb-6 flex flex-col bg-primary/5">
      <div className="flex flex-col lg:flex-row lg:justify-between  lg:mr-10">
        <div className="flex items-center justify-center px-6 lg:px-10">
          <Link to="/" className="mr-2 md:mr-4">
            <img
              className="block h-7 w-auto pr-2 sm:pr-0"
              src={Logo}
              alt="Workflow"
            />
          </Link>
          <h2>nova web masters</h2>
        </div>
        <NavigationLinks />
      </div>
      <ul className="flex justify-center mt-9 space-x-6 text-primary lg:mt-7">
        <li>
          <a
            href="https://www.facebook.com/novawebmasters"
            rel="noopener noreferrer"
            target="_blank"
          >
            <svg
              className="h-6 w-6"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
              ></path>
            </svg>
          </a>
        </li>
        {/* <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
            />
          </svg>
        </li> */}
      </ul>
      <span className="text-sm text-center mt-9">
        <span className="inline-block mr-1 translate-y-px">©</span>
        <span>nova web masters {new Date().getFullYear()}</span>
      </span>
    </footer>
  );
};
